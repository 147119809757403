<template>
	<div class="setup-code">
		<h2>
			<i :class="iconName"></i> {{pageTitle}}
		</h2>
		<div class="row">
			<div class="col-md-4">
				<h3>Install the Site Auditor to your website</h3>
				<div class="sc-btns">
					<router-link tag="button" class="btn btn-outline-secondary sc-btn"
								 :to="{name: 'dashboard.setup-form.build-form'}">Back
					</router-link>
					<button @click="finishSetup()" class="btn btn-primary sc-btn ">Done</button>
				</div>
			</div>
		</div>

		<div class="sc-cnt" v-if="dataLoaded">
			<div class="row">
				<template v-if="formatType==='embed' || formatType==='popup'">
					<div class="col-md-6">
						<h4>Option <span>1</span></h4>
						<p class="sc-descr">Add the embed code below to start receiving leads.</p>
						<div class="code">
							<div class="code-lines">
								<span v-for="index in linesCount" :key="index">{{index}}</span></div>
							<div class="code-main">
								<div class="code-copy">
									<button class="code-copy__text btn btn-primary" v-clipboard="embedCode"
											@success="handleSuccess"
											@error="handleError">
										<i class="icon-copy"></i> Copy Code</button>
								</div>
								<div class="code-content">
									<code>
										<span class="code--comment">&lt;!-- Begin SiteAuditor.com --&gt; </span>
										<div class="code--default" style="line-height: 20px">{{embedCode}}</div>

										<span class="code--comment">&lt;!-- End SiteAuditor.com --&gt;</span>
									</code>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<h4>Option <span>2</span></h4>
						<p class="sc-descr">or email the embed code to your teammate</p>
						<form class="sc-cnt-form sc-cnt-form--horizontal" action="#">
							<div class="form-group">
								<input class="form-control" type="email" placeholder="Enter Email" v-model="emailToSend"/>
							</div>
							<a class="btn btn-primary" :href="`mailto:${emailToSend}?subject=Embed Code`">Send Embed Code</a>
						</form>
					</div>

				</template>
				<template v-else-if="formatType==='landing'">
					<div class="col-md-6">
						<p class="sc-descr">Copy below URL and share it wherever you need to generate leads</p>
						<div class="sc-cnt-form sc-cnt-form--horizontal">
							<div class="form-group">
								<input class="form-control" type="text" placeholder="" v-model="embedCode" readonly>
							</div>
							<button class="btn btn-primary"
									v-clipboard="embedCode"
									@success="handleSuccess"
									@error="handleError">
								<i class="icon-copy"></i> Copy Url
							</button>
						</div>
						<p class="sc-descr">Do you want customize the link?
							<a class="blue-link" @click="$emit('openDomainSettings')">
								Go to Custom Domain Setting
							</a>
						</p>
					</div>
				</template>

			</div>
		</div>
	</div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {FORMAT_TYPES} from "../../../../../shared/constants";

    export default {
        name: "EmbedCode",
        data() {
            return {
                pageTitle: '',
                formatType: '',
                dataLoaded: false,
                embedCode: '',
                emailToSend: '',
				linesCount: 10
            }
        },
        mounted () {
            if (!this.chosenFormat.type) {
                this.$router.push({name: 'dashboard.setup-form.choose-format'})
            } else {
                this.formatType = this.chosenFormat.type;
                this.pageTitle = this.chosenFormat.title;
                if (!this.isLanding) {
                    window.addEventListener('resize', this.handleResize);
                }
            }
            this.$store.dispatch('setupForm/getEmbedCode', this.chosenFormat.type).then((response) => {
                this.embedCode = response;
                if (!this.isLanding) {
                    this.$nextTick(() => {
                        this.handleResize();
                    });
				}

            }).catch((error) => {
                this.dataLoaded = true;
                this.showErrorNotifications(error);
            });

            this.dataLoaded = true;

        },

        beforeDestroy () {
            window.removeEventListener('resize', this.handleResize)
        },

        methods: {
            handleResize () {
                var el = document.getElementsByClassName('code--default')[0];

                var divHeight = el.offsetHeight;

                var lineHeight = parseInt(el.style.lineHeight);

                this.linesCount = divHeight / lineHeight + 2;
            },
            finishSetup() {
                this.$store.dispatch('setupForm/updateProfileSetup', {'finish_setup': true})
                    .then(() => {
                        this.showNotifications({message: 'Setup successfully saved', type: 'success'});

                        setTimeout( () => {
                            this.$router.push({name: 'dashboard.leads.my-leads'})
                        } , 1000);


                    }).catch((error) => {
                    this.showErrorNotifications(error);
                });

            },

            handleSuccess() {
                this.showNotifications({message: 'Copied to clipboard', type: 'success'});
            },

            handleError() {
                this.showNotifications({message: 'Can not copy', type: 'danger'});
            },

        },
        computed: {
            isEmbed() {
                return this.formatType === FORMAT_TYPES.EMBED;
            },
            isPopup() {
                return this.formatType === FORMAT_TYPES.POPUP;
            },
            isLanding() {
                return this.formatType === FORMAT_TYPES.LANDING_PAGE;
            },
            iconName() {
                return this.isPopup ? 'svg-image-popup-code' : 'icon-code-' + this.formatType;
            },
            ...
                mapGetters({
                    chosenFormat: "setupForm/getFormat"
                })

        }
    }
</script>

<style scoped>
	.form-control:disabled, .form-control[readonly] {
		background-color: #FFFFFF;
	}
</style>
